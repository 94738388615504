<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-3">
        <div class="row" v-if="!isLoad">
            <div class="col-12" v-if="questions.quest.length">
                <div class="card p-0 mb-3" v-for="(item, index) in questions.quest" :key="index">
                    <div class="card-title border-bottom bg-grey px-3 py-2 mb-0">
                        <a class="float-right font-xs" @click="deleteAlert(item.id)" href="javascript:void(0)"><i class="fas fa-trash text-danger"></i></a>
                    </div>
                    <div class="card-body text-center" v-if="item.quest_type == 1">
                        <video class="w-50 h-auto" controls>
                            <source :src=" item.path" type="video/mp4">
                        </video>
                    </div>
                    <div class="card-body" v-if="item.quest_type == 2">
                        <audio class="w-100" controls>
                            <source :src=" item.path" type="audio/mpeg">
                        </audio>
                    </div>
                    <div class="card-body ql-editor">
                        <div v-html="item.question"></div>
                    </div>
                    <div v-if="item.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                        File Upload
                    </div>
                    <div v-if="item.submission_type == 2" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                        Essay
                    </div>
                    <div v-if="item.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                        <table class="table m-0">
                            <tr v-for="(opt, j) in item.option" :key="j">
                                <td width="5%" :class="{'bg-success' : opt.id == item.answer,'bg-grey' : opt.id != item.answer }"></td>
                                <td>{{opt.option}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center" v-else>
                <img :src="'/images/empty.png'" alt="" width="300">
                <h1 class="text-muted">Data don't exist</h1>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-12 text-center py-3">
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import 'vue2-editor/dist/vue2-editor.css'
import axios from 'axios'
export default {
    props: ['newData'], 
    watch:{
        newData: function(newVal){
            if(newVal) {
                this.getQuestions();
            }
        }
    },
    data(){
        return{
            id : this.$route.params.idQuestionnaire,
            slug : this.$route.params.idCourse,
            media : process.env.VUE_APP_URL_CLOUD,
            isLoad : true,
            questions: []
        }
    },
    created(){
        this.getQuestions()
    },
    methods:{
        async getQuestions(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire/quest?id=${this.id}&slug=${this.slug}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.questions = res.data.data
                this.isLoad = false
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        slug: this.slug,
                        questionnaire_id: this.id,
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire/quest`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Questionnaire Open!',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getQuestions()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Questionnaire Open!',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}
</script>